<script setup lang="ts">
import {getURLSearchParam} from "@/js/general";
import PhpServiceFormView from "@/components/basic/php-wrappers/PhpServiceFormView.vue";

const code = getURLSearchParam('code');
const email = getURLSearchParam('email');

let url = '?f=global&f2=resetPassword';
if (code) {
  url += `&code=${code}`;
}
if (email) {
  url += `&email=${email}`;
}
</script>

<template>
  <PhpServiceFormView :url="url" public/>
</template>

<style scoped>

</style>